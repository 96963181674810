
var InfiniteScroll = require('infinite-scroll')

/**
 * http://usejsdoc.org/
 */
$(function() {
	$('.tip').tooltip();

	if( $('#filter-bar a.active').length && ! $('#filtering-list a.active').length) {

		$('#filter-bar a.active').each(function() {
			var activeFilter = $(this).clone()
			$('#filtering-list').append(activeFilter.append('<i class="fa fa-remove ml-1"></i>'))
		})

		$('#filtering').collapse('show')
	}
	
	$('#project-tags').on('show.bs.collapse', function () {
		$('#year-tags').collapse('hide')
		$('#filter-btn-project').addClass('bg-secondary text-white')
		$('#filter-btn-year').removeClass('bg-secondary text-white')
	})
	
	$('#year-tags').on('show.bs.collapse', function () {
		$('#project-tags').collapse('hide')
		$('#filter-btn-year').addClass('bg-secondary text-white')
		$('#filter-btn-project').removeClass('bg-secondary text-white')
	})

	if ($('#infinite-scroll-container').length) {
		new InfiniteScroll( '#infinite-scroll-container', {
			path: '.pagination_next',
			append: '.post',
			history: false,
		});
	}
})